import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import Layout from "../../layout"
import Seo from "../../seo"
import BlogHeader from "../header"
import { withTrans } from "../../../i18n/withTrans"
import {
  Grid,
  Snackbar,
  Modal,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ListContent from "../listContent"
import axios from "axios"
import i18next, { t } from "i18next"
import { Close, FileCopy, Share } from "@material-ui/icons"
import { getArticleDateFormat } from "../../../helpers/stringHelper"
import {
  FacebookShare,
  LinkedinShare,
  TelegramShare,
  TwitterShare,
  WhatsappShare,
} from "react-share-kit"
import { navigate } from "gatsby"
import "./detail.scss"

const initialArticleDetail = {
  title: "",
  content: "",
  featuredImage: "",
  channelSource: "",
  publishedTime: "",
  category: "",
  subcategory: "",
  slug: "",
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
  thumbnail: "",
  labels: [],
}

const BlogDetail = () => {
  const isSm = useMediaQuery(useTheme().breakpoints.up("md"))
  const [isClient, setIsClient] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const { host, protocol, pathname } = useLocation()
  const articleSlug = pathname.split("/")[pathname.split("/").length - 1]
  const forPreview =
    pathname.split("/")[pathname.split("/").length - 2] == "preview"
      ? true
      : undefined
  const [articleDetail, setArticleDetail] = useState(initialArticleDetail)

  const browserReady = () => typeof window !== "undefined"

  function handleShareModal() {
    setOpenShareModal(!openShareModal)
  }

  function handleSnackbar() {
    setOpenSnackbar(!openSnackbar)
  }
  async function shareSocialReport({ channel, refererUrl }) {
    if (channel == "Clipboard") {
      navigator.clipboard.writeText(refererUrl)
      handleSnackbar()
    }

    await axios.post(
      `${process.env.GATSBY_API_URL}/api/scpublic/article/${articleDetail.slug}/share`,
      {
        sharePlatform: channel,
        referrerUrl: refererUrl,
      }
    )
  }

  async function visitContentReport(slug) {
    const ipAddress = await axios.get("https://api.ipify.org/?format=json")

    await axios.post(
      `${process.env.GATSBY_API_URL}/api/scpublic/article/${slug}/visit`,
      {
        ipAddress: ipAddress.data.ip,
        userAgent: navigator.userAgent,
        referrerUrl:
          document.referrer.length == 0
            ? `${protocol}//${host}`
            : document.referrer,
      }
    )
  }

  async function getArticleDetail() {
    setArticleDetail(initialArticleDetail)

    const response = await axios.get(
      forPreview
        ? `${process.env.GATSBY_API_URL}/api/scpublic/article/preview/${articleSlug}`
        : `${process.env.GATSBY_API_URL}/api/scpublic/article/${articleSlug}`,
      {
        params: {
          language: i18next.language,
        },
      }
    )
    setArticleDetail(response.data)
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    getArticleDetail()
  }, [articleSlug, i18next.language])

  useEffect(() => {
    if (!forPreview) {
      visitContentReport(articleSlug)
    }
  }, [])

  return (
    <Layout key={isClient}>
      <Seo
        title={
          forPreview ? `Preview ${articleDetail.title}` : articleDetail.title
        }
        meta={
          forPreview
            ? []
            : [
                {
                  property: `og:title`,
                  content: articleDetail.metaTitle,
                },
                {
                  name: `twitter:title`,
                  content: articleDetail.metaTitle,
                },
              ]
        }
        keywords={forPreview ? null : articleDetail.metaKeyword}
        description={forPreview ? null : articleDetail.metaDescription}
        key={0}
      />
      <BlogHeader />
      <Grid container className="blog-detail">
        <Grid item lg={7} xs={12} className="detail-content">
          <img
            src={
              forPreview ? articleDetail.thumbnail : articleDetail.featuredImage
            }
            className="featured-img"
            title={articleDetail.title}
            alt={articleDetail.title}
          />
          <div className="title-date">
            <div className="title">{articleDetail.title}</div>

            <div className="date">{`${articleDetail.subcategory} ${
              forPreview
                ? ""
                : " | " + `${getArticleDateFormat(articleDetail.publishedTime)}`
            }`}</div>
          </div>
          <div
            className="main-content"
            dangerouslySetInnerHTML={{ __html: articleDetail.content }}
          />

          <div className="content-footer">
            <div className="source-share">
              <div className="source">
                {articleDetail.channelSource == undefined ||
                articleDetail.channelSource.length == 0
                  ? ""
                  : `${t("blog.source")} : ${articleDetail.channelSource}`}
              </div>

              <div className="share-button" onClick={handleShareModal}>
                <Share /> {`${t("blog.share")} `}
              </div>
            </div>

            <div className="labels">
              {articleDetail.labels == undefined ? (
                <></>
              ) : (
                articleDetail.labels
                  .filter(e => e != "null")
                  .map((e, i) => (
                    <div
                      key={i}
                      className="label-item"
                      onClick={() => {
                        navigate(`/blog/${pathname.split("/")[2]}?hashtag=${e}`)
                      }}
                    >{`#${e} `}</div>
                  ))
              )}
            </div>
          </div>

          <Modal open={openShareModal} onClose={handleShareModal} keepMounted>
            <div className="share-popup">
              <div className="header">
                <div className="title">{`${t("blog.share")} `}</div>
                <div className="close" onClick={handleShareModal}>
                  <Close />
                </div>
              </div>
              <div className="channels">
                <div
                  onClick={() => {
                    shareSocialReport({
                      channel: "Whatsapp",
                      refererUrl: browserReady() ? window.location.href : "",
                    })
                  }}
                  className="share-socmed-icon"
                >
                  <WhatsappShare
                    url={browserReady() ? window.location.href : ""}
                    alt
                    title={articleDetail.title}
                    round
                    size={isSm ? "64px" : "48px"}
                  />
                  <div className="tooltip-text">Whatsapp</div>
                </div>
                <div
                  onClick={() => {
                    shareSocialReport({
                      channel: "Telegram",
                      refererUrl: browserReady() ? window.location.href : "",
                    })
                  }}
                  className="share-socmed-icon"
                >
                  <TelegramShare
                    url={browserReady() ? window.location.href : ""}
                    title={articleDetail.title}
                    round
                    size={isSm ? "64px" : "48px"}
                  />
                  <div className="tooltip-text">Telegram</div>
                </div>
                <div
                  onClick={() => {
                    shareSocialReport({
                      channel: "Twitter",
                      refererUrl: browserReady() ? window.location.href : "",
                    })
                  }}
                  className="share-socmed-icon"
                >
                  <TwitterShare
                    url={browserReady() ? window.location.href : ""}
                    title={articleDetail.title}
                    round
                    size={isSm ? "64px" : "48px"}
                  />
                  <div className="tooltip-text">Twitter</div>
                </div>
                <div
                  onClick={() => {
                    shareSocialReport({
                      channel: "Facebook",
                      refererUrl: browserReady() ? window.location.href : "",
                    })
                  }}
                  className="share-socmed-icon"
                >
                  <FacebookShare
                    url={browserReady() ? window.location.href : ""}
                    title={articleDetail.title}
                    round
                    size={isSm ? "64px" : "48px"}
                  />
                  <div className="tooltip-text">Facebook</div>
                </div>
                <div
                  onClick={() => {
                    shareSocialReport({
                      channel: "LinkedIn",
                      refererUrl: browserReady() ? window.location.href : "",
                    })
                  }}
                  className="share-socmed-icon"
                >
                  <LinkedinShare
                    url={browserReady() ? window.location.href : ""}
                    title={articleDetail.title}
                    round
                    size={isSm ? "64px" : "48px"}
                  />
                  <div className="tooltip-text">LinkedIn</div>
                </div>

                <div className="share-socmed-icon">
                  <div className="box-content">
                    <div
                      className="content-copy"
                      onClick={() => {
                        shareSocialReport({
                          channel: "Clipboard",
                          refererUrl: browserReady()
                            ? window.location.href
                            : "",
                        })
                      }}
                    >
                      <FileCopy
                        style={{
                          fill: "#fff",
                        }}
                      />
                    </div>
                  </div>
                  <div className="tooltip-text">Copy URL</div>
                </div>
              </div>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={1000}
                onClose={handleSnackbar}
                message={
                  !browserReady()
                    ? "Failed to copy, only use secure protocol (https), current protocol is not secure (http)"
                    : navigator.clipboard == undefined
                    ? "Failed to copy, only use secure protocol (https), current protocol is not secure (http)"
                    : "Copied to Clipboard"
                }
              />
            </div>
          </Modal>
        </Grid>

        {articleDetail.category.length != 0 && (
          <ListContent category={articleDetail.category} />
        )}
      </Grid>
    </Layout>
  )
}

export default withTrans(BlogDetail)
