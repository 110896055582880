import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import CustomPagination from "../Pagination"
import { navigate } from "gatsby"
import axios from "axios"
import i18next, { t } from "i18next"
import {
  getArticleDateFormat,
  getDefaultArticleCategory,
} from "../../helpers/stringHelper"
import "./listContent.scss"
import { categoryMapping } from "../../constants/blogCategoryList"

const ListContent = ({ category }) => {
  const [selectedTopic, setSelectedTopic] = useState(0)
  const [topics, setTopics] = useState([
    {
      name: t("blog.all"),
      defaultName: "",
      category: "",
    },
  ])
  const [page, setPage] = useState(1)

  const [articleResponse, setArticleResponse] = useState({
    currentPage: page,
    totalPage: 0,
    totalDbItem: 0,
    items: [],
  })

  async function getSubCategoryDatas() {
    const response = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/article/subcategory`,
      {
        params: {
          language: i18next.language,
        },
      }
    )
    if (response.data) {
      const tempTopics = [
        {
          name: t("blog.all"),
          defaultName: "",
          category: "",
        },
      ]
      setTopics(
        tempTopics.concat(response.data.filter(e => e.category == category))
      )
    }
  }
  async function getArticleList() {
    const mapIdx = categoryMapping
      .map(e => (i18next.language == "en" ? e.en : e.id))
      .indexOf(category.toLowerCase())

    const response = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/article`,
      {
        params: {
          page: page,
          size: 8,
          orders: "publishedTime-DESC",
          category: categoryMapping[mapIdx == -1 ? 0 : mapIdx].key,
          subcategory:
            selectedTopic == 0 ? null : topics[selectedTopic].defaultName,
          language: i18next.language,
        },
      }
    )
    setArticleResponse(response.data)
  }

  useEffect(() => {
    getSubCategoryDatas()
  }, [i18next.language])

  useEffect(() => {
    getArticleList()
  }, [page, selectedTopic, i18next.language])

  return (
    <Grid item lg={3} xs={12} className="list-content">
      <div className="topic-list">
        <div className="part-title">{t("blog.topics")}</div>
        <div className="list">
          {topics.map((e, i) => (
            <div
              key={i}
              className={i == selectedTopic ? "item active" : "item"}
              onClick={() => {
                setSelectedTopic(i)
              }}
            >
              {e.name}
            </div>
          ))}
        </div>
      </div>
      <div className="latest-list">
        <div className="part-title">
          {`${t("blog.latest-in")}  ${category}`}
        </div>
        <div className="list">
          {articleResponse.items.map((e, i) => (
            <div
              key={i}
              className="mini-article-card"
              onClick={() => {
                navigate(
                  `/blog/${getDefaultArticleCategory({
                    category: e.category,
                  })}/${e.slug}`,
                  {
                    replace: true,
                  }
                )
              }}
            >
              <img src={e.thumbnail} className="thumb" />
              <div className="info">
                <Typography className="title">{e.title}</Typography>
                <Typography className="date-info">{`${
                  e.subcategory
                } | ${getArticleDateFormat(e.publishedTime)}`}</Typography>
              </div>
            </div>
          ))}
        </div>

        {articleResponse.totalPage > 1 && (
          <div className="pagination">
            <CustomPagination
              pageCount={articleResponse.totalPage}
              page={articleResponse.currentPage}
              onPageChange={(e, v) => {
                setPage(v)
              }}
            />
          </div>
        )}
      </div>
    </Grid>
  )
}

export default ListContent
